import React from "react";
import Content from '../views/Leasing/Main';

export default ({location}) => (
  <Content location={location} seo={{
    lang: 'en',
    title: "A complete connected car solution for leasing companies",
    description: 'A connected car solution for leasing companies makes vehicle management easier. Leverage up-to-date insights into the vehicles’ condition.',
    meta: [{
      name: 'keywords',
      content: 'Connected car solution for leasing'
    }]
  }} />
);